<template>
    <nav class="nav-menu">
      <li>
        <router-link to="/">в главное меню</router-link>
      </li>
      
    </nav>
    <img id="mini-logo" src="../assets/logo.svg" alt="">
    <div class="presentation">
      <h3>география деятельности</h3>
      <div class="slides" style="margin-left:-340px">
        <img src="/media/map.png" alt="">
      </div>
    </div>
  </template>
  
  
  <script>
  
  //import Owl from 'vue-owl-carousel'
  //import Slick from 'vue-slick-carousel'
  import {mapGetters, mapState} from 'vuex'

  export default {
    name: "SlideThird",
    methods: {
      setNext(){
        return this.$store.commit('setPresentation',this.next)
      },
      setPrev(){
        return this.$store.commit('setPresentation',this.prev)
      }
    },
    computed: {
      ...mapState({
        id:'presentationId',
        presentations: 'presentations',
      }),
      ...mapGetters({
        amount: 'presentationLength',
        current: 'currentPresentation'
      }),
      prev: function(){
        return (((this.id-1)+this.$store.getters.presentationLength)%this.$store.getters.presentationLength)+1;
      },
      next: function(){
        
        return (this.id+1)%this.$store.getters.presentationLength+1;
      }
  
  
    }
  }
  </script>
  
  <style scoped>
    .alfa-logo{
        position: absolute;
        left:1020px;
        background: #FFD100;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding:3px 10px;
        display: block;
    }
    .alfa-logo img{
        width:150px;

    }
   .nav-menu{
      position: absolute;
      top:0px;
      right: 350px;
      display: flex;
      padding-top: 0px;
    }
    .slides{
        justify-content: flex-start ;
    }
    .slides img{
        max-width: 1700px;
        max-height: 800px;
    }
    
  </style>
  