<template>
   <div class="main">
    <router-view/>
   </div>
  
</template>

<style>
  @import url(./assets/css/norm.css);
  @import url(./assets/fonts/stylesheet.css);
html,body{
  height:1080x;
  max-height: 1080px;
  width: 1920px;
  display: flex;
  font-family: 'europecregular';
}
body{
  position: relative;
}
#app {
  min-height: 100%;
  width:100%;
  font-family: 'europecregular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#mini-logo{
  width:173px;
  height: 124px;
  position: absolute;
  left: 1560px;
  top: 7px;
}
.home{
  height: 1080px;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.main{
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: url('assets/img/bg.jpeg');
    background-size: cover;
}
.presentation{
  height:1080px
}

.nav-menu{
      position: absolute;
      top:0px;
      right: 350px;
      display: flex;
      padding-top: 0px;
    }
    .nav-menu li{
      list-style: none;
    }
    .nav-menu li a{
      display: flex;
      padding: 20px 25px;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 23px;
      font-weight: bold;
      text-decoration: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #FFD100;
      margin: 0 5px 2px;
    }
    .slides{
      margin-left:110px;
    }
    .presentation{
      padding:10px;
      box-sizing: border-box;
      width: 1920px;
      margin:0px auto;
    }
    .presentation h3{
        padding-top: 100px;
        text-align: left;
        color: #FFF;
        font-size: 46px;
        margin-left: 110px;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .presentation p{
      color:#FFF;
      text-align: right;
      margin-right:100px;
      font-size: 20px;
    }
    .slide{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 800px;
    max-width: 1700px;
  }
  .slide img{
    object-fit: contain;
    display: block;
    max-height: 800px;
    width: 100%;
  }
</style>
