<template>
    <nav class="nav-menu">
      <li>
        <router-link to="/">в главное меню</router-link>
      </li>
      
    </nav>
    <img id="mini-logo" src="../assets/logo.svg" alt="">
    <img id="mif" src="../assets/img/mif.png" alt="">
    <div class="presentation">
      <h3>Дорожные битумы</h3>
      <div class="slides">
        <div class="slide"><img src="/slides/1-slide.png" alt=""></div>
      </div>
      <p>*в соответствии с марочным ассортиментом дорожных битумов, доступных на НПЗ</p>
    </div>
  </template>
  
  
  <script>
  
  //import Owl from 'vue-owl-carousel'
  //import Slick from 'vue-slick-carousel'
  import {mapGetters, mapState} from 'vuex'

  export default {
    name: "PresentationView",
    methods: {
      setNext(){
        return this.$store.commit('setPresentation',this.next)
      },
      setPrev(){
        return this.$store.commit('setPresentation',this.prev)
      }
    },
    computed: {
      ...mapState({
        id:'presentationId',
        presentations: 'presentations',
      }),
      ...mapGetters({
        amount: 'presentationLength',
        current: 'currentPresentation'
      }),
      prev: function(){
        return (((this.id-1)+this.$store.getters.presentationLength)%this.$store.getters.presentationLength)+1;
      },
      next: function(){
        
        return (this.id+1)%this.$store.getters.presentationLength+1;
      }
  
  
    }
  }
  </script>
  
  <style scoped>
    #mif{
        position: absolute;
        left:1270px;
        top:150px;
    }
    
  </style>
  