import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PresentationView from '../views/Presentation.vue'
import SlideOne from '../views/SlideOne.vue'
import SlideThird from '../views/SlideThird.vue'
import SlideFour from '../views/SlideFour.vue'
import SlideFive from '../views/SlideFive.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/presentation/:id',
    name: 'PresentationView',
    component: PresentationView,
  },
  {
    path: '/slide-one',
    name: 'SlideOne',
    component: SlideOne,
  },
  {
    path: '/slide-third',
    name: 'SlideThird',
    component: SlideThird,
  },
  {
    path: '/slide-four',
    name: 'SlideFour',
    component: SlideFour,
  },
  {
    path: '/slide-five',
    name: 'SlideFive',
    component: SlideFive,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
