<template>
    <nav class="nav-menu">
      <li>
        <router-link to="/">в главное меню</router-link>
      </li>
      
    </nav>
    <img id="mini-logo" src="../assets/logo.svg" alt="">
    <router-link class="alfa-logo" to="/presentation/2"><img id="alfa-logo" src="../assets/img/alfabit-yellow.png" alt=""></router-link>
    <div class="presentation">
      <h3>Полимерно-битумные вяжущие</h3>
      <div class="slides">
        <div class="slide"><img src="/slides/slide-3-2.png" alt=""></div>
      </div>
      <p class="left">Марки доступны как в наливном, так и в фасованном виде</p>
    </div>
  </template>
  
  
  <script>
  
  //import Owl from 'vue-owl-carousel'
  //import Slick from 'vue-slick-carousel'
  import {mapGetters, mapState} from 'vuex'

  export default {
    name: "SlideThird",
    methods: {
      setNext(){
        return this.$store.commit('setPresentation',this.next)
      },
      setPrev(){
        return this.$store.commit('setPresentation',this.prev)
      }
    },
    computed: {
      ...mapState({
        id:'presentationId',
        presentations: 'presentations',
      }),
      ...mapGetters({
        amount: 'presentationLength',
        current: 'currentPresentation'
      }),
      prev: function(){
        return (((this.id-1)+this.$store.getters.presentationLength)%this.$store.getters.presentationLength)+1;
      },
      next: function(){
        
        return (this.id+1)%this.$store.getters.presentationLength+1;
      }
  
  
    }
  }
  </script>
  
  <style scoped>
    .alfa-logo{
        position: absolute;
        left:1020px;
        background: #FFD100;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding:14px 47px;
        display: block;
    }
    .alfa-logo img{
        width:150px;

    }
    .presentation p{
      margin-left:110px;
      text-align: left;
    }

  </style>
  