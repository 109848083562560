import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      presentationId:0,
      presentations:[],
    }
  },
  getters: {
    currentPresentation: state => {
      return state.presentations[state.presentationId]
    },
    presentationLength: state => {
      return Object.keys(state.presentations).length
    }
  },
  mutations: {
    initiation(state){
      state.presentations = [
      {path:'/media/1',amount:15,header:'БИТУМНЫЕ ВЯЖУЩИЕ ПО PG'},{path:'/media/2',amount:3,header:'Преимущества ПБВ алфавит'}]
    },
    setPresentation(state,id){
      state.presentationId = id-1
      state.currentPresentation = state.presentations[id-1]
      //console.log(state.presentations[state.presentationId])
    }

  },
  actions: {

  },
  modules: {

  }
})
