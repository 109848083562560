<template>
  <div class="home">
    <img id="main-logo" alt="Роснефть Битум" src="../assets/logo.svg">
    <ul class="menu">
      <li><router-link to="/slide-one">Дорожные <br/>битумы</router-link></li>
      <li><router-link to="/presentation/1">битумные <br/> вяжущие<br/> по PG</router-link></li>
      <li><router-link to="/slide-third">Полимерно-<br/>битумные<br/> вяжущие</router-link></li>
      <li><router-link to="/slide-four">Битумные <br/>эмульсии</router-link></li>
      <li><router-link to="/slide-five">География <br/>деятельности</router-link></li>
    </ul>
  </div>
</template>

<script>



export default {
  name: 'HomeView',
  components: {

  }
}
</script>
<style>
  #main-logo{
    width:467px;
    height:336px;
    margin-top:200px;
    margin-bottom: 225px;
  }
  .menu{
    display: flex;
    justify-content: center;
  }
  .menu li{
    list-style: none;
  }
  .menu li a{
    display: flex;
    width: 298px;
    height: 111px;
    color:#000;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 10px;
    font-size: 28px;
    padding: 30px 0;
    justify-content: center;
    font-family: 'europecregular';
    align-items:center;
    font-weight: bold;
    border: 1px solid #FFD100;
    margin:10px;
    background:#FFD100;
  }
  .menu li a:hover,.menu li a:active{
    color:#FFD100;
    background: rgba(0,0,0,0);
    transition: color ease-in, background-color ease-in;
  }

  .slides{
      margin-left:110px;
    }
    .slide{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 800px;
  }
  .slide img{
    object-fit: contain;
    display: block;
    max-height: 800px;
    width: 100%;
  }
</style>