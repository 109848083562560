<template>
  <nav class="nav-menu">
    <li>
      <a @click="setPrev()"><img src="../assets/img/prev.svg" alt=""></a>
    </li>
    <li>
      <a @click="setNext()"><img src="../assets/img/next.svg" alt=""></a>
    </li>
    <li>
      <router-link to="/">в главное меню</router-link>
    </li>
  </nav>
  <img id="mini-logo" src="../assets/logo.svg" alt="">
  <img v-if="id==1" id="alfa-logo" src="../assets/img/alfabit-black.png" alt="">
  <div class="presentation">
    <h3 v-if="id!==1">{{current.header}}</h3>
    <h3 v-if="id==1&&index==0">Преимущества ПБВ Альфабит</h3>
    <h3 v-if="id==1&&index>0">Сегменты ПБВ Альфабит</h3>
    <div class="slides" v-bind:class="{ first: !id }">
      <swiper :slides-per-view="1" :space-between="0" @swiper="onSwiper" @slideChange="onSlideChange" :key="`test-one`"
        :name="'neveragain'">
        <swiper-slide v-for="index in current.amount" class="slide" :key="index">
          <img :src="`${current.path}/image-${index}.jpg`" alt="">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>


<script>

//import Owl from 'vue-owl-carousel'
//import Slick from 'vue-slick-carousel'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapGetters, mapState } from 'vuex'
import 'swiper/css'

export default {

  name: "PresentationView",
  components: { Swiper, SwiperSlide },
  beforeMount() {
    this.$store.commit('initiation');
    this.$store.commit('setPresentation', this.$route.params.id)

  },
  data() {
    return {
      swiper: null,
      index: 0
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    setNext: function () {
      this.swiper.slideNext();
      // this.swiper.slideNext();
    },
    setPrev: function () {
      this.swiper.slidePrev();
      // this.swiper.slidePrev();
    },
    onSlideChange: function () {
      this.index = this.swiper.activeIndex;
    }
  },
  computed: {
    ...mapState({
      id: 'presentationId',
      presentations: 'presentations',
    }),
    ...mapGetters({
      amount: 'presentationLength',
      current: 'currentPresentation'
    }),
    // prev: function(){
    //   return (((this.id-1)+this.$store.getters.presentationLength)%this.$store.getters.presentationLength)+1;
    // },
    // next: function(){

    //   return (this.id+1)%this.$store.getters.presentationLength+1;
    // }


  }
}
</script>

<style scoped>
#mini-logo {
  width: 173px;
  height: 124px;
  position: absolute;
  left: 1560px;
  top: 7px;
}

#alfa-logo {
    position: absolute;
    left: 120px;
    top: 50px;
    width: 260px;
}

.slides {
  margin: 0 auto;
  width: 1674px;
}
.slides.first{
  margin-left:110px;
  width: 1420px;
}
.presentation {
  padding: 10px;
  box-sizing: border-box;
  width: 1920px;
  margin: 0px auto;
}

.presentation h3 {
  padding-top: 100px;
  text-align: left;
  color: #FFF;
  font-size: 46px;
  margin-left: 110px;
  text-transform: uppercase;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 800px;
}

.slide img {
  object-fit: contain;
  display: block;
  max-height: 800px;
  width: 100%;
}
</style>
